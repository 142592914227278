import {EventPostStatus} from "../models/api/events";
import PagedFilterBase from "./PagedFilterBase";

class EventFilter extends PagedFilterBase {
    BusinessBranchId?: string;
    UserId?: string;
    Status = EventPostStatus.None;
    Active? = true

    constructor(init?: Partial<EventFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}

export default EventFilter
