import { render, staticRenderFns } from "./EventIndex.vue?vue&type=template&id=02b4dace&scoped=true&"
import script from "./EventIndex.vue?vue&type=script&lang=ts&"
export * from "./EventIndex.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b4dace",
  null
  
)

export default component.exports