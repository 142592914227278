
import { EventModel } from 'tradingmate-modules/src/models/api/events'
import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Card from './Card.vue'
import Badge from '../status/Badge.vue'

@Component({
  components: {
    Card,
    Badge
  }
})
export default class EventCard extends Vue {
  @Prop({ required: true })
  private readonly eventModel!: EventModel

  private isoToDisplayDate = Utils.isoToDisplayDate
  private isoToDisplayTime = Utils.isoToDisplayTime

  // getTime (startDate: string, endDate: string): string {
  //   const startTime = this.isoToDisplayTime(startDate)
  //   const endTime = this.isoToDisplayTime(endDate)
  //   if (startTime === endTime) {
  //     return startTime
  //   } else { return `${startTime} - ${endTime}` }
  // }

  getDate (start: string, end: string): string {
    const startDate = this.isoToDisplayDate(start)
    const endDate = this.isoToDisplayDate(end)
    if (startDate === endDate) {
      return startDate
    } else { return `${startDate} - ${endDate}` }
  }
}
